.footer .img-fluid {
    padding-bottom: 20px !important;
  
  }
  .footer {
    padding: 1rem 0 0 0;
    background-color: #e4e4e4;
  
  }
  p{
    text-align: center;
  }

  .footer ul li {
    margin-bottom: 10px;
  }
  
  .bottomBar a {
    color: #000;
  }
  
  .footer .copyright {
    margin-bottom: 0px;
  }
  
  .footer .copyright
  {
    font-family: 'DM Sans', Helvetica, Arial, Lucida, sans-serif;
    font-size: 16px;
    text-align: left !important; 
    font-weight: 700;
  color: #C2C2C2 !important;
  }
  .footer .credits {
    font-family: 'DM Sans', Helvetica, Arial, Lucida, sans-serif;
    font-size: 16px;
    text-align: right !important; 
    font-weight: 700;
  color: #C2C2C2 !important;
  }
  
  .fa {
    padding: 7px;
    font-size: 18px;
    width: 34px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #3a47bc;
  }
  .fa-facebook {
    background: rgba(245, 245, 245, 0);
    color: #3a47bc;
}
.threads{
  background: rgba(245, 245, 245, 0);
  color: #3a47bc;
  width: 34px !important;
  height: 32px !important;
}
.bottomBar{
  background-color: #566a9b;
  padding-bottom: 20px;
}
.MainFooter{
padding-bottom: 30px;
}

.bottomBar p {
  font-family: 'DM Sans', Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  

  font-weight: 700;
color: #C2C2C2 !important;
}
.bottomBar a {
  color: #C2C2C2 !important;
}

.footer a {
  color: #000;
  text-decoration: auto;
}
.fa {
  padding: 6px 8px 6px 8px;
  font-size: 18px;
  width: 34px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
  border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    border-color: #3a47bc;
}

.fa:hover {
    opacity: 0.7;
}

.fa-facebook {
  background: rgba(245,245,245,0);
  color: #3a47bc !important
}
.fa-linkedin {
  background: rgba(245,245,245,0);
  color: #3a47bc  !important
}
.fa-twitter {
  background: rgba(245,245,245,0);
  color: #3a47bc  !important
}

.fa-instagram {
  background: rgba(245,245,245,0);
  color: #3a47bc  !important
}

.fa-pinterest {
  background: rgba(245,245,245,0);
  color: #3a47bc  !important
}
.fa-quora{
  background: rgba(245,245,245,0);
  color: #3a47bc  !important
}
.footer-logo{
 
  
  width: 57% !important;
}
.footer-img-fluid3{
 
  width: 41% !important;
 
}
@media (min-width: 576px) {
	.footer-logo{
		 
		width: 25% !important;
	}
	.footer-img-fluid3{
		
    width: 25% !important;
	}
}
 

@media only screen and (max-width: 2100px) {
  .footer h3 {
    padding-top: 30px !important;
    font-size: 27px !important;
    margin-bottom: 10px !important;
    text-align: center !important;
    font-weight: 600 !important;
  }
}
@media only screen and (max-width: 600px) {

.footer h3 {
  padding-top: 20px !important;
  font-size: 26px !important;
  margin-bottom: 10px !important;
  text-align: center !important;
  font-weight: 600 !important;
}
}

@media only screen and (max-width: 2100px) {
  .footer .justify-content-md-normal2{
    width: 46%;
  }
  footer .justify-content-md-normal3{
    width: 8%;
  }
}
@media only screen and (max-width: 600px) {

  .footer .justify-content-md-normal2{
    width: 40%;
  }
  footer .justify-content-md-normal3{
    width: 20%;
  }
}