.order-deveice-input{
    margin:5px 0px;
}
.quote-text{
    font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
    font-size: 59px;
    line-height: 70px;
    margin-bottom: 41px !important;
    font-weight: 700;
    text-align: center;
    color: #868686!important;
}