/* .header{
    background-color: rgb(123, 231, 197);
    padding: 20px;
    width: 100%;
    height: 110px;
} */
.pointer{
  cursor: pointer;
}
#header {
    height: 115px;
    transition: all 0.5s;
    z-index: 997;
    transition: all 0.5s;
  }
  
  #header.header-scrolled {
    background: rgba(39, 70, 133, 0.8);
    height: 112px;
  }
  
  #header .logo h1 {
    font-size: 28px;
    margin: 0;
    padding: 4px 0;
    line-height: 1;
    font-weight: 500;
  }
  
  #header .logo h1 a,
  #header .logo h1 a:hover {
    color: #fff;
    text-decoration: none;
  }
  
  #header .logo img {
    padding-top: 0px;
    padding-bottom: 10px;
    margin: 0;
    max-height: 95px;
  }
  #basic-navbar-nav {
    position: absolute;
    right: 0px;
     }

.offMenu{
  display: none;
}


  @media only screen and (max-width: 768px) {
#basic-navbar-nav{
  position: unset;
}
   
  }
