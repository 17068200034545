.App {
  text-align: center;
  background-color: rgb(200, 228, 219);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.main {
  width: 1320px;
  background-color: aquamarine;
  height: 100%;
}
.MainBody {
  padding: 50px;
}

.CardTitle {
  height: 70px;
}
@media only screen and (max-width: 767px) {
  .hero-section {
    display: none !important;
  }
}

.hero-section {
  /* background: linear-gradient(to right, rgba(39, 70, 133, 0.8) 0%, rgba(61, 179, 197, 0.8) 100%), url(../img/hero-bg.jpg); */
  position: relative;
  /* height: 500px; */
}

/* ,
.hero-section>.container>.row */
.row {
  padding-top: 20px;
}
p {
  margin-top: 0;
  margin-bottom: 0rem !important;
}
.hero-section {
  height: 100%;
  padding-bottom: 30px;
}
.OrderDevice {
  height: 100%;
  min-height: 559px;
  padding: 0px 13px 50px 13px; 
}
/* .hero-section.inner-page {
  height: 260vh;
  min-height: 0;
} */

/* .hero-section.inner-page .hero-text {
  transform: translateY(-150px);
  margin-top: -120px;
} */

@media screen and (max-width: 992px) {
  .hero-section.inner-page .hero-text {
    margin-top: 80px;
  }
}

.hero-section h1 {
  font-size: 26px;
  color: #7ebcb1;
  font-weight: 700;
  margin-bottom: 17px;
}

@media screen and (max-width: 992px) {
  .hero-section h1 {
    font-size: 26px;
    color: #7ebcb1;
    text-align: center;
    margin-top: 40px;
  }
}
@media screen and (max-width: 520px) {
  .hero-section h1 {
    font-size: 26px;
    color: #7ebcb1;
    text-align: center;
    margin-top: 0px;
  }
}

@media screen and (max-width: 992px) {
  .hero-section .hero-text-image {
    margin-top: 4rem;
  }
}
@media screen and (max-width: 520px) {
  .hero-section .hero-text-image {
    margin-top: 0rem;
  }
}
.hero-section p {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  line-height: 23px;
  width: 68%;
  font-weight: 500;
  color: black;
  text-align: left;
}
@media screen and (max-width: 520px) {
  .hero-section p {
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-size: 16px;
    line-height: 23px;
    width: 100%;
    font-weight: 500;
    color: black;
    text-align: center;
  }
}
.hero-section .iphone-wrap {
  position: relative;
  text-align: end;
}

@media screen and (max-width: 992px) {
  .hero-section .iphone-wrap {
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  .hero-section .iphone-wrap .phone-2 {
    position: relative;
    top: 0;
    max-width: 100%;
  }
}

@media screen and (max-width: 992px) {
}
.phone-3 {
  height: 400px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.hero-section .iphone-wrap {
  position: relative;
}

@media screen and (max-width: 992px) {
  .hero-section .iphone-wrap {
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  .hero-section .iphone-wrap .phone-2 {
    position: relative;
    top: 0;
    max-width: 100%;
  }
}
@media screen and (max-width: 520px) {
  .hero-section .btn-No-Long {
    width: 158px;
    padding: 10px 0px !important;
  }
}
.hero-section .fade-right {
  padding: 0px;
  width: 170px;
}
/* @media screen and (max-width: 2100px) {
  .hero-section .fade-right {
    width: 158px;
    padding: 10px 0px !important;
  }
} */

/* ////////////////////// 1st section Mobile//////////////////////////// */
/* @media screen and (max-width: 600px) {
  .hero-section-mobile{
    display: none;
  }
} */
@media only screen and (min-width: 520px) {
  .hero-section-mobile {
    display: none !important;
  }
}

.hero-section-mobile {
  /* background: linear-gradient(to right, rgba(39, 70, 133, 0.8) 0%, rgba(61, 179, 197, 0.8) 100%), url(../img/hero-bg.jpg); */
  position: relative;
  /* height: 500px; */
}


.hero-section-mobile {
  height: 100%;
  padding-bottom: 30px;
}
@media screen and (max-width: 992px) {
  .hero-section.inner-page .hero-text {
    margin-top: 80px;
  }
}
.hero-section-mobile h1 {
  font-size: 26px;
  color: #7ebcb1;
  font-weight: 700;
  margin-bottom: 17px;
}

@media screen and (max-width: 992px) {
  .hero-section-mobile h1 {
    font-size: 26px;
    color: #7ebcb1;
    text-align: center;
    margin-top: 40px;
  }
}
@media screen and (max-width: 520px) {
  .hero-section-mobile h1 {
    font-size: 26px;
    color: #7ebcb1;
    text-align: center;
    margin-top: 0px;
  }
}

@media screen and (max-width: 992px) {
  .hero-section-mobile .hero-text-image {
    margin-top: 4rem;
  }
}
@media screen and (max-width: 520px) {
  .hero-section-mobile .hero-text-image {
    margin-top: 0rem;
  }
}
.hero-section-mobile p {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  line-height: 23px;
  width: 68%;
  font-weight: 500;
  color: black;
  text-align: left;
}
@media screen and (max-width: 520px) {
  .hero-section-mobile p {
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-size: 16px;
    line-height: 23px;
    width: 100%;
    font-weight: 500;
    color: black;
    text-align: center;
  }
}
.hero-section-mobile .iphone-wrap {
  position: relative;
  text-align: end;
}

@media screen and (max-width: 992px) {
  .hero-section-mobile .iphone-wrap {
    text-align: center;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 992px) {
  .hero-section-mobile .iphone-wrap .phone-2 {
    position: relative;
    top: 0;
    max-width: 100%;
  }
}

.hero-section-mobile .iphone-wrap {
  position: relative;
}

@media screen and (max-width: 992px) {
  .hero-section-mobile .iphone-wrap {
    text-align: center;
  }
}

@media screen and (max-width: 992px) {
  .hero-section-mobilen .iphone-wrap .phone-2 {
    position: relative;
    top: 0;
    max-width: 100%;
  }
}
@media screen and (max-width: 520px) {
  .hero-section-mobile .btn-No-Long {
    width: 100%;
    padding: 10px 0px !important;
  }
}
.hero-section-mobile .fade-right {
  padding: 0px;
  width: 170px;
}
@media screen and (max-width: 520px) {
  .hero-section-mobile .fade-right {
    width: 100%;
    padding: 10px 0px !important;
  }
}

@media screen and (max-width: 2200px) {
  .phone-2 {
    width: 500px;
  }
}

@media screen and (max-width: 520px) {
  .phone-2 {
    width: 350px;
  }
}
@media screen and (max-width: 520px) {
  .DriverDefects .phone-2 {
    width: 350px;
  }
}
@media only screen and (max-width: 600px) {
  .OrderDevice h2 {
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-size: 30px;
    line-height: 43px;
    font-weight: 500;
    color: #7ebcb1;
  }
  .OrderDevice .BookADemo-Form {
    background-color: #ffffff;
    border-radius: 30px 30px 30px 30px;
    overflow: hidden;
    padding: 30px 10px;
    box-shadow: 0px 2px 18px 0px #d6d6d6;
  }
}
@media (min-width:601px) and (max-width: 2200px) {
.OrderDevice h2 {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 33px;
  line-height: 53px;
  font-weight: 500;
  color: #7ebcb1;
}
.OrderDevice .BookADemo-Form {
  background-color: #ffffff;
  border-radius: 30px 30px 30px 30px;
  overflow: hidden;
  padding: 30px;
  box-shadow: 0px 2px 18px 0px #d6d6d6;
}
}
@media screen and (max-width: 2200px) {
 
  .OrderDevice h1 {
    color: #7ebcb1;
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-size: 61px;
    line-height: 70px;
    padding-top: 25px !important;
    font-weight: 700;
  }

}

.OrderDevice .fade-right {
  padding-right: 4%;
}
.OrderDevice h5 {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  color: #868686 !important;
}
.OrderDevice ul li {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  line-height: 33.1px;
  font-weight: 500;
  color: #868686 !important;
}

.OrderDevice h3 {
  color: #868686 !important;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 27px;
  font-weight: 500;
}

.Driver-Checks-sec .centered-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.DriverChecksimg {
  width: 250px;
}

.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0 0px 0px;
  font-size: 17px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
  white-space: nowrap;
  transition: 0.3s;
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
}

.navbar Link i,
.navbar Link:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar Link:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #7ebcb1 !important;
}
@media (min-width: 992px) {
  .text-lg-start {
      
      padding-top: 70px;
  }

}
@media screen and (max-width: 520px) {
  .text-lg-start {
    padding-top: 0px;
  }
  .OrderDevice h1 {
    color: #7ebcb1;
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-size: 41px;
    line-height: 45px;
    
    font-weight: 700;
}
}
.img-fluid1 {
  width: 160px;
}
.img-fluid2 {
  width: 160px;
  margin-left: 11px;
}
.img-fluid3 {
  padding-top: 36px;
}
.footer .img-fluid3 {
  padding-top: 27px;
}
.btn-No-Long {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif !important;
  background-color: rgba(0, 0, 0, 0) !important;
  position: relative !important;
  border-radius: 10px 10px 10px 10px !important;
  overflow: hidden !important;
  border-width: 2px !important;
  border-color: #7ebcb1 !important;
  height: 46px !important;
  padding-top: 11px !important;
  padding-bottom: 8px !important;
  width: 170px;
  font-size: 15px !important;
  color: #7ebcb1 !important;
  /* transform: translateX(0px) translateY(+23px) !important; */
}

.btn-Get-started {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif !important;
  background-color: rgba(0, 0, 0, 0) !important;
  position: relative !important;
  border-radius: 10px 10px 10px 10px !important;
  overflow: hidden !important;
  border-width: 2px !important;
  border-color: #7ebcb1 !important;
  height: 46px !important;
  padding-top: 11px !important;
  padding-bottom: 8px !important;
  width: 369px !important;
  font-size: 15px !important;
  color: #7ebcb1 !important;
  /* transform: translateX(0px) translateY(+23px) !important; */
}
@media screen and (max-width: 520px) {
  .btn-Get-started {
    width: 100% !important;
  }
}
.justify-content-md-normal {
  width: fit-content;
  padding: 10px;
}
.form-group {
  padding: 10px 0px 10px 0px;
}

#header {
  height: 115px;
  transition: all 0.5s;
  z-index: 997;
  transition: all 0.5s;
}

#header.header-scrolled {
  background: rgba(39, 70, 133, 0.8);
  height: 112px;
}

#header .logo h1 {
  font-size: 28px;
  margin: 0;
  padding: 4px 0;
  line-height: 1;
  font-weight: 500;
}

#header .logo h1 a,
#header .logo h1 a:hover {
  color: #fff;
  text-decoration: none;
}

#header .logo img {
  padding-top: 0px;
  padding-bottom: 10px;
  margin: 0;
  /* max-height: 95px; */
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/

.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}

.navbar .dropdown ul li {
  min-width: 200px;
}

.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 15px;
  color: #101c36;
}

.navbar .dropdown ul a i {
  font-size: 12px;
}

.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #2d71a1;
}

.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}

.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}

.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}

@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }

  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #7ebcb1;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: contents;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  /* position: fixed; */
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(27, 49, 94, 0.9);
  transition: 0.3s;
  z-index: 999;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile Link,
.navbar-mobile Link:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #101c36;
}

.navbar-mobile Link:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #2d71a1;
}

.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}

.navbar-mobile .dropdown ul li {
  min-width: 200px;
}

.navbar-mobile .dropdown ul Link {
  padding: 10px 20px;
}

.navbar-mobile .dropdown ul Link i {
  font-size: 12px;
}

.navbar-mobile .dropdown ul Link:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > Link {
  color: #2d71a1;
}

.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

.Electronic h2 {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 42px;
  color: #868686;
  line-height: 1.1em;
}
.Electronic {
  padding-bottom: 30px;
}
.Electronic .text-center {
  padding-bottom: 30px;
}
.GlobalColor {
  color: #7ebcb1 !important;
}

.text-center1 {
  border: solid;
  border-radius: 147px 147px 147px 147px;
  overflow: hidden;
  border-width: 5px;
  border-color: #7fbcb1;
  height: 300px;
  width: 300px !important;
  padding-top: 79px;
  text-align: center;
}
.text-center1 h3 {
  font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
  color: #333 !important;
  font-size: 25px;
}
.text-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Electronic-row {
  padding:  10px 20px 10px 20px;
}
.Driver-Checks-sec {
  background-color: #eaedf4;
  padding: 30px 0px 30px 0px;
}
.Driver-Checks-sec p {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
}


.OneApp-section h2 {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 61px;
  color: #868686;
  line-height: 70px;
  font-weight: 700;
  padding-top: 50px;
}
@media screen and (max-width: 520px) {
  .OneApp-section h2 {
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-size: 42px;
    color: #868686;
    line-height: 49px !important;
    font-weight: 700;
    padding-top: 0px;
    text-align: center;
  }
  .OneApp-section p {
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-size: 16px;
    line-height: 23px;
    padding-bottom: 0px;
    font-weight: 500;
    color: #272727 !important;
    text-align: center;
  }
  .OneApp-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 0px;
  }
  .GeAFreeQuote button{
    background: rgb(127, 188, 177);
    width: 100%;
    margin: 30px 0px;
    border: 0px;
}
}
@media screen and (min-width: 521px) {
  .OneApp-section p {
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
    color: #272727 !important;
    text-align: left;
  }
  .OneApp-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px 25px 0px;
  }
  .w-100 button{
    background: rgb(127, 188, 177);
    width: 130px;
    margin: 30px 0px;
    border: 0px;
}
}
.TripTrackinginG {
  /* background-image: url('./assets/img/techgv-37164.png'); */

  max-width: 100%;
  height: auto;
  background-color: #edeff5;
}
.TripTrackinginG1 {
  /* background-image: url('./assets/img/techgv-37164.png'); */

  max-width: 100%;
  height: auto;
}
.TripTrackinginG3{
  padding: 30px 0px 30px 0px;
}
@media screen and (max-width: 520px) {
 
  .TripTrackinginG3 h2 {
    font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
    font-size: 29px;
    color: #414141 !important;
    padding-top: 30px;
  }
  .TripTrackinginG3 .feature-12 p {
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-weight: 600;
    font-size: 17px;
    color: #8f8f8f !important;
  }
  .TripTrackinginG3  .col-sm-2{
    padding-right: 8px;
}
}
@media screen and (min-width: 520px) {
  .TripTrackinginG3 h2 {
    font-family: "Roboto", Helvetica, Arial, Lucida, sans-serif;
    font-size: 58px;
    color: #868686 !important;
    padding-top: 30px;
  }
  .TripTrackinginG3 .feature-12 p {
    font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
    font-weight: 600;
    font-size: 23px;
    color: #8f8f8f !important;
  }
  .TripTrackinginG3  .col-sm-2{
    padding-right: 0px;
}
}

.TripTrackinginG3 .feature-2 {
  background-color: #ffffff;
  border-color: #000000;
  padding-top: 14px;
  padding-right: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
  border: solid 1px;
  
    padding: 20px 20px 20px 20px;

}
.TripTrackinginG3 .feature-3 {
  background-color: #566a9b;
  border-radius: 7px 7px 7px 7px;
  overflow: hidden;
  padding-top: 14px;
  padding-right: 14px;
  padding-bottom: 14px;
  padding-left: 14px;
  padding: 20px 20px 20px 20px;
 
}
@media screen and (max-width: 600px) {
  .TripTrackinginG3 .feature-3 {

    margin: 20px 0px 20px 0px;

}
}
@media (min-width: 576px) {

  .TripTrackinginG3 .col-sm-1 {
    flex: 0 0 auto;
    width: 3.5%;
  }
}
@media (min-width: 768px) {
  .TripTrackinginG3 .col-md-3 {
    flex: 0 0 auto;
    width: 31%;
  }
}
.TripTrackinginG3 .feature-2 p {
  text-align: left !important;
  color: #666;
}
.TripTrackinginG3 .feature-2 h5 {
  text-align: left !important;
  color: #191d23 !important;
}
.TripTrackinginG3 .feature-3 p,
h5 {
  text-align: left !important;
  color: #ffffff !important;
}
.ReduceyourFleet {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  overflow: hidden !important;
  border-color: #000000 !important;
  padding-right: 14px !important;
  padding-left: 14px !important;
  border: solid 1px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color: #1d4ed8 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  text-decoration: auto !important;
  width: 100% !important;
}
.ReduceyourFleet:hover {
  color: var(--bs-btn-hover-color) !important;
  background-color: var(--bs-btn-hover-bg) !important;
  border-color: #5a5a5a !important;
}
.feature-3 .ReduceyourFleet {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif !important;
  font-weight: 600 !important;
  font-size: 15px !important;
  overflow: hidden !important;
  border-color: #000000 !important;
  padding-right: 14px !important;
  padding-left: 14px !important;
  border: solid 1px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  color: #1d4ed8 !important;
  background-color: rgb(255, 255, 255) !important;
  text-decoration: auto !important;
  width: 100% !important;
}
.feature-3 .ReduceyourFleet:hover {
  color: var(--bs-btn-hover-color) !important;
  background-color: var(--bs-btn-hover-bg) !important;
  border-color: #ffffff !important;
}
.TripTrackinginG3 .circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #dde1ec;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
  border-color: #d6d6d6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.TripTrackinginG3 .circle-x {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
  border-color: #d6d6d6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-circle{
 
  height: 47px;
}
@media (min-width: 576px) {
  .TripTrackinginG3 .icon-circle .col-sm-1 {
    flex: 0 0 auto;
    width: 10%;
  }
  /* .icon-circle {
    flex: 0 0 auto;
    width: 10%;
  } */


}

.TripTrackinginG3 .circle i {
  color: #2c478b;
  font-size: 18px;
}

.DriverDefects h2 {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 22px !important;
  font-weight: 600;
  color: #272727 !important;
}

.DriverDefects p {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  color: #272727 !important;
  text-align: left !important;
}

.Blog-posts {
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.BlogPost {
  width: 100%;
  /* background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px; */
  padding: 0px 0px 20px 0px;
  margin-bottom: 20px;
}

.Post-meta {
  margin-top: 10px;
  text-align: left;
}
.Post-meta p {
  text-align: left;
}
.hero-section222 {
  padding-top: 10px;
  background-color: #fff;
}

.hero-section222 h2 {
  font-size: 22px;
}
.hero-section222 p {
  font-size: 18px;
}
.hero-section222 img {
  width: 100% !important;
}
@media (min-width: 576px) {
}

/* //////Footer///// */
.Footer .text_field {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  line-height: 23px;
  width: 68%;
  font-weight: 500;
  color: black;
  text-align: left;
}

.OrderDevice .text_field {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 16px;
  line-height: 23px;
  width: 100%;
  font-weight: 500;
  color: black;
  text-align: left;
}
/*  */
.TripTrackinginG-Mobile h1 {
  font-weight: 600;
}

.TripTrackinginG-Mobile p {
  font-weight: 500;
}
@media screen and (min-width: 600px) {
  .TripTrackinginG-Mobile {
    display: none;
  }
  .DriverDefects {
    background-image: url("./assets/img/Group-37146-1.png");
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 1000px;
    padding-top: 20%;
  }
.Mobile-img{
  display: none;
}
}

@media screen and (max-width: 600px) {
  .TripTrackinginG {
    display: none;
  }
  .DriverDefects {
    
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 800px;
    padding-top: 20%;
  }
}
.sidebar {
  height: 100%;
  width: 250px;
  position: relative;
  top: 0;
  right: 0;
  background-color: #f8f9fa;
  padding-top: 20px;
}

/* Page content */
.content {
  margin-left: 250px;
  padding: 20px;
}
.blog-section {
  height: 100%;
  padding-bottom: 50px;
}

.blog-section p {
  text-align: left;
  padding: 5px 0px 5px 0px;
}
.blog-section h4 {
  padding: 10px 0px 5px 0px;
}

.blog-section .ComericalVehicle img {
  width: 100%;
}
.blog-section .img2 {
  text-align: center;
}
@media screen and (min-width: 600px) {
  .blog-section .img2 img {
    width: 500px;
  }
}

@media screen and (max-width: 600px) {
  .blog-section .img2 img {
    width: 100%;
  }
}
.IMg5-1 {
  text-align: center;
}
@media screen and (min-width: 600px) {
  .blog-section .IMg5-1 img {
    width: 700px !important;
  }
}

@media screen and (max-width: 600px) {
  .blog-section .IMg5-1 img {
    width: 100% !important;
  }
}

.blog-section img {
  padding: 20px 0px 20px 0px;
}
.navbar-toggler {
  border: none !important;
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}
.navbar-toggler:focus {
  box-shadow: none;
}
.werwer
  {
    padding: 10px 0px;
}
.OrderDevice h3 {
  font-family: "Poppins", Helvetica, Arial, Lucida, sans-serif;
  font-size: 28px;
  line-height: 42px;
  font-weight: 600;
  color: #7ebcb1 !important;
  text-align: center;
}
.PrivacyPolicy-section{
  padding: 30px 0px 30px 0px;
}
.PrivacyPolicy-section p{
  padding: 5px 0px 5px 0px;
  text-align: start;
}
.PrivacyPolicy-section h4{
  padding: 5px 0px 5px 0px;
  text-align: start;
}